"use client";
import { RotatingLines } from "react-loader-spinner";

const Loading: React.FC = () => {
  return (
    <div className="loading-page">
      <div className="loading-spinner">
        <RotatingLines
          visible={true}
          width="56"
          strokeColor="grey"
          strokeWidth="6"
          ariaLabel="rotating-lines-loading"
        />
        <br />
        <span className="nv-heading-2 text-gray-600">Please, wait...</span>
      </div>
    </div>
  );
};

export default Loading;
